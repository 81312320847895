






















import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(_, { root }) {
    const state = reactive({
      headers: [
        {
          value: "name",
          text: root.$tc("panel.event.add.alert.templateName"),
          sortable: false,
        },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("company-registration-form-template", {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { companyRegistrationFormTemplates, total } }) => {
          state.empty = false;
          state.items = companyRegistrationFormTemplates.map(
            (companyRegistrationFormTemplates: any, index: number) => ({
              id: index,
              name: "",
              ...companyRegistrationFormTemplates,
            })
          );
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const copyForm = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`company-registration-form-template/${id}`)
        .then(({ data: { companyRegistrationFormTemplate } }) => {
          state.loading = true;

          const name = prompt(
            root.$tc(
              "panel.globalTools.registrationForm.form.list.enterNewTemplateName"
            ),
            `${companyRegistrationFormTemplate.name} (kopia)`
          );

          if (name) {
            axiosInstance
              .post("company-registration-form-template", {
                name,
                groups: companyRegistrationFormTemplate.groups,
              })
              .then(fetchData)
              .catch((error) => {
                console.log(error);
                alert(root.$tc("alert.cantCopyTemplate"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    return { state, fetchData, copyForm };
  },
});
